import Bignumber from 'bignumber.js';

import { ImmutableMap } from '@daikin-tic/dxone-com-lib';

import { Comment, SopExecutableStatus, SopObjectAchievement, SopRequestResult } from './sop-group.model';
import { SOP_WF_NUM_PARAM, SOP_WF_PARAM_TYPE, SopParams } from './sop-paramater.model';

const NUMBER_REG_EXP = /^-?(0|([1-9]\d*))(\.\d+)?$/;
export const isNumberValue = (value: string): boolean => NUMBER_REG_EXP.test(value);

export const SOP_OBJ_TYPE = {
  branch: 'Branch',
  combineMultipleInput: 'CombineMultipleInput',
  confirmInstruction: 'ConfirmInstruction',
  enterProcessData: 'EnterProcessData',
  inputItem: 'InputItem',
  inputTime: 'InputTime',
  manageEquipment: 'ManageEquipment',
  showPiPastData: 'ShowPIPastData',
} as const;
export type SopObjType = (typeof SOP_OBJ_TYPE)[keyof typeof SOP_OBJ_TYPE];

export const SOP_REPEAT_STATUS = {
  overwrite: 1,
  add: 2,
} as const;

export const SOP_TOTAL_VALUE_FLG = {
  followReferenceMode: 0,
  total: 1,
} as const;

export const SOP_REFERENCE_MODE = {
  first: 0,
  sync: 1,
  latest: 2,
} as const;

export const SOP_INPUT_ITEM = {
  limitCheckOption: {
    empty: undefined,
    none: 0,
    fixedRelativeValue: 1,
    percentageRelativeValue: 2,
  },
  achvFlg: {
    noYetInput: 0,
    accepted: 1,
    confirmed: 2,
  },
} as const;

export const AUTO_COMMENT_MANAGEMENT = {
  required: -1,
  notRequired: 0,
} as const;

export type SopObjectDefinition = {
  instructNo: string;
  processCode: string;
  processOrder: number;
  runTimeInstructId: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  sopOrder: number;
  objType: SopObjType;
  issuedDate: string;
  startPlanDate: string;
  endPlanDate: string;
  startDate: string;
  endDate: string;
  labelName: string;
  baseLabelName: string;
  sopContents: string;
  objStatus: '';
  repeatStatus: (typeof SOP_REPEAT_STATUS)[keyof typeof SOP_REPEAT_STATUS];
  nextObjId: string;
} & SopObjectDefinitionParamater;

export type SopObjectDefinitionParamater = {
  wfParamName: string;
  displayDefineName: string;
  wfParamType: (typeof SOP_WF_PARAM_TYPE)[keyof typeof SOP_WF_PARAM_TYPE];
  wfNumParam: string;
  wfStrParam: string;
  wfDatParam: string;
};

export type Confirmation = {
  confirmKind: number;
  confirmValue: string;
  confirm: string;
  confirmOrder: number;
  autoCommentFlg: (typeof AUTO_COMMENT_MANAGEMENT)[keyof typeof AUTO_COMMENT_MANAGEMENT];
};

export type SumOfYield = {
  sumOfYield?: string;
  runTimeInstructId: string;
  batchNo: number;
  outputItemCode: string;
};

export type SumOfInputVolume = {
  sumOfInput?: string;
  runTimeInstructId: string;
  batchNo: number;
  inputItemCode: string;
  inputOrder: number;
};

export type Instruct = {
  instructNo: string;
  productionVolume?: string;
  displayLotNo: string;
};

export type ProcessInputVolume = {
  volume?: string;
  runTimeInstructId: string;
  batchNo: number;
  inputItemCode: string;
  inputOrder: number;
};

export type ProcessOutputVolume = {
  sumYield?: string;
  runTimeInstructId: string;
  itemCode: string;
};

export type SopObjectStatus = {
  isExecutable: SopExecutableStatus;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
};

export type SopLabelValue = {
  labelValueResult: SopRequestResult;
  achvValue1: string;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  sopLabelName: string;
  totalValueFlg: (typeof SOP_TOTAL_VALUE_FLG)[keyof typeof SOP_TOTAL_VALUE_FLG];
  referenceMode: (typeof SOP_REFERENCE_MODE)[keyof typeof SOP_REFERENCE_MODE];
  achvOrder: number;
};

export type TagValue = {
  tagValueResult: SopRequestResult;
  tagValue: string;
  errorMessage: string;
  runTimeInstructId: string;
  batchNo: number;
  tagName: string;
};

export type UpdatedSopObject = {
  updateResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  updaterCode: string;
  updateTime: string;
  nextLabel: string;
  equipmentStatusCode: string;
  comment: string;
  deviationId: string;
  deviationComment: string;
  limitValue: string;
  globalTagName: string;
  runTimeInstructId: string;
  workflowSet: string;
  workflowSetSeqNo: number;
  outputItemCode: string;
  financeDate: string;
  containerNo: string;
  palletNo: string;
  effectiveNumber: number;
};

export type UpdatedSopObjectLogBook = {
  updateResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  updaterCode: string;
  updateTime: string;
  nextLabel: string;
  equipmentStatusCode: string;
  comment: string;
};

export type UpdatedSopObjectMultiple = {
  updateResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  updaterCode: string;
  updateTime: string;
  comment: string;
  deviationId: string;
  deviationComment: string;
  limitValue: string;
  globalTagName: string;
  runTimeInstructId: string;
  workflowSet: string;
  workflowSetSeqNo: number;
  outputItemCode: string;
  financeDate: string;
  containerNo: string;
  palletNo: string;
  effectiveNumber: number;
};

export type UpdatedSopObjectInputItem = {
  updateResult: SopRequestResult;
  errorMessage: string;
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2: string;
  value3: string;
  value4: string;
  value5: string;
  value6: string;
  updaterCode: string;
  updateTime: string;
  nextLabel: string;
  equipmentStatusCode: string;
  remainBackIsChecked: string;
  comment: string;
};

export type UpdatedSopObjectSkip = {
  skipResult: SopRequestResult;
  errorMessage: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  updaterCode: string;
  updateTime: string;
  nextLabel: string;
  comment: string;
  deviationId: string;
  deviationComment: string;
};

export type UpdatedSopObjectAchievement = {
  updateAchvResult: SopRequestResult;
  errorMessage: string;
  runTimeInstructId: string;
  workflowSet: string;
  workflowSetSeqNo: number;
  objId: string;
  batchNo: number;
  achvOrder: number;
  instructNo: string;
  processCode: string;
  workflowId: string;
  sopOrder: number;
  achvValue1: string;
  achvValue2: string;
  achvValue3: string;
  achvValue4: string;
  achvValue5: string;
  achvValue6: string;
  achvDate: string;
  updaterName: string;
  comment: string;
  deviationId: string;
  updaterCode: string;
  updateTime: string;
};

export type SopEquipment = {
  equipmentCode: string;
  equipmentName: string;
  instruction: string;
  logBookTreatMode: string;
  currentStatus: string;
  recordValues: { code: string; name: string }[];
};

export type Equipment = {
  equipmentCode: string;
  equipmentName: string;
  equipmentStatusCode: string;
  equipmentStatusName: string;
  equipmentStatusName2: string;
  equipmentKind?: number;
  effectivePeriodDate?: string;
  equipmentTypeCode?: string;
  equipmentTypeName?: string;
};

export type SopFormula = {
  calculateFormulaResult: SopRequestResult;
  formulaResult: string;
  errorMessage: string;
  runTimeInstructId: string;
  batchNo: number;
  formula: string;
};

export type ProcessInput = {
  inputItemCode: string;
  inputOrder: number;
  volume: string;
  itemName: string;
  unitCode: string;
  unitName: string;
  limitCheckOption?: (typeof SOP_INPUT_ITEM.limitCheckOption)[keyof typeof SOP_INPUT_ITEM.limitCheckOption];
  aboveUpperPoint?: string;
  upperPoint?: string;
  lowerPoint?: string;
  belowLowerPoint?: string;
  recipeOrder: number;
  stockWeightUnitRatio?: string;
  runTimeInstructId: string;
  batchNo: number;
  editionNo: string;
  zoneCode: string;
  otherZoneGroup: string;
  achvFlg?: (typeof SOP_INPUT_ITEM.achvFlg)[keyof typeof SOP_INPUT_ITEM.achvFlg];
  divisionNo: number;
  divisionBranchNo: number;
  lotNo: string;
  baleNo: string;
  containerNo: string;
  palletNo: string;
  achvAddWeight: string;
  takeOutValue?: string;
  displayLotNo: string;
  makerLotNo: string;
  lotSeqNo?: number;
  runTimeZoneCode: string;
};

export type ProcessInputAchievement = {
  runTimeInstructId: string;
  batchNo: number;
  inputItemCode: string;
  inputOrder: number;
  editionNo: string;
  recipeOrder: number;
  zoneCode: string;
  otherZoneGroup: string;
  volume: string;
  limitCheckOption?: (typeof SOP_INPUT_ITEM.limitCheckOption)[keyof typeof SOP_INPUT_ITEM.limitCheckOption];
  aboveUpperPoint?: string;
  upperPoint?: string;
  lowerPoint?: string;
  belowLowerPoint?: string;
  achvFlg?: (typeof SOP_INPUT_ITEM.achvFlg)[keyof typeof SOP_INPUT_ITEM.achvFlg];
  divisionNo: number;
  divisionBranchNo: number;
  lotNo: string;
  baleNo: string;
  containerNo: string;
  palletNo: string;
  achvAddWeight: string;
  takeOutValue?: string;
  itemName: string;
  displayLotNo: string;
  makerLotNo: string;
  weightUnitCode: string;
  unitName: string;
  lotSeqNo?: number;
  runTimeZoneCode: string;
  updateTime: string;
};

export type Inventory = {
  inventoryResult: SopRequestResult;
  errorMessage: string;
  itemCode: string;
  inputOrder: number;
  lotNo: string;
  baleNo: string;
  palletNo: string;
  containerNo: string;
  unitNumber: string;
  availableNumber: string;
  zoneCode: string;
  itemName: string;
  displayLotNo: string;
  itemType: number;
  editionNo: string;
  makerLotNo: string;
  inputDate: string;
  unitName: string;
  lotSeqNo: number;
  qualityValue: string;
  weighingDone: number;
  isMixedLot: number;
  baleMadeInstructNo: string;
  zoneName: string;
};

export type PiPastData = {
  piPastDataResult: SopRequestResult;
  errorMessage: string;
  tagName: string;
  piAfTime: string;
  retrievalMode: number;
  tagValue: string;
};

export type Achievement = Pick<
  SopObjectAchievement,
  'achvOrder' | 'achvValue1' | 'achvValue2' | 'achvValue3' | 'achvValue4' | 'achvValue5' | 'achvValue6' | 'achvDate' | 'updaterName'
>;

export type ObjSyncStatus = {
  isSyncObjStatus?: boolean;
  isSyncObjExecutable?: boolean;
  isSyncEquipment?: boolean;
  isSyncProcessInput?: boolean;
  isSyncProcessInputAchievement?: boolean;
  isSyncInventory?: boolean;
  isSyncConfirmation?: boolean;
  isSyncObjComments?: boolean;
  isSyncSumOfYield?: boolean;
  isSyncSumOfInputVolume?: boolean;
  isSyncInstruct?: boolean;
  isSyncProcessInputVolume?: boolean;
  isSyncProcessOutputVolume?: boolean;
};

type SopObjectRequiredProps = Omit<SopObjectDefinition, keyof SopObjectDefinitionParamater | 'processOrder' | 'objStatus'> & {
  sopParams: SopParams;
  version: number;
};

type SopObjectOptionalProps = ObjSyncStatus &
  Partial<
    Pick<SopObjectAchievement, 'objStatus' | 'hasRecordComment' | 'hasDeviation' | 'workflowSet' | 'workflowSetSeqNo' | 'instructValue'>
  > &
  Partial<SopObjectStatus> & {
    achievements?: Achievement[];
    comments?: Comment[];
  };

export type SopObjectBranch = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObject,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  > & {
    sopLabelValues?: (SopLabelValue & { labelId: string })[];
    sopFormulas?: (SopFormula & { formulaId: string })[];
  };

export type SopObjectCombineMultiple = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObjectMultiple,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  >;

export type SopObjectConfirmInstruction = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObject,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  > & {
    confirmations?: Confirmation[];
  };

export type SopObjectEnterProcessData = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObject,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  > & {
    sopLabelValues?: (SopLabelValue & { labelId: string })[];
    sopFormulas?: (SopFormula & { formulaId: string })[];
    sumOfYield?: SumOfYield;
    sumOfInputVolume?: SumOfInputVolume;
    instruct?: Instruct;
    processInputVolume?: ProcessInputVolume;
    processOutputVolume?: ProcessOutputVolume;
  } & Partial<TagValue>;

export type SopObjectInputItem = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<Omit<UpdatedSopObjectInputItem, 'comment'>> & {
    sopLabelValues?: (SopLabelValue & { labelId: string })[];
    processInputs?: ProcessInput[];
    processInputAchievements?: ProcessInputAchievement[];
    inventories?: Inventory[];
  };

export type SopObjectInputTime = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObject,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  >;

export type SopObjectManageEquipment = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<Omit<UpdatedSopObjectLogBook, 'comment'>> & {
    sopLabelValues?: (SopLabelValue & { labelId: string })[];
    sopEquipment?: SopEquipment;
  };

export type SopObjectShowPiPastData = SopObjectRequiredProps &
  SopObjectOptionalProps &
  Partial<
    Omit<
      UpdatedSopObject,
      | 'comment'
      | 'deviationComment'
      | 'globalTagName'
      | 'runTimeInstructId'
      | 'workflowSet'
      | 'workflowSetSeqNo'
      | 'outputItemCode'
      | 'financeDate'
      | 'containerNo'
      | 'palletNo'
      | 'effectiveNumber'
    >
  > & {
    sopLabelValues?: (SopLabelValue & { labelId: string })[];
  } & Partial<PiPastData>;

export type SopObject = SopObjectBranch &
  SopObjectCombineMultiple &
  SopObjectConfirmInstruction &
  SopObjectEnterProcessData &
  SopObjectInputItem &
  SopObjectInputTime &
  SopObjectManageEquipment &
  SopObjectShowPiPastData;

export type SopObjectDefinitionReadParams = { instructNo: string; processCode: string; workflowId: string };
export type ConfirmationReadParams = { instructNo: string; confirmKind: number };
export type SopObjectReadParams = { instructNo: string; processCode: string; batchNo: number; workflowId: string; objId: string };
export type SumOfYieldReadParams = { runTimeInstructId: string; batchNo: number; outputItemCode: string };
export type SumOfInputVolumeReadParams = { runTimeInstructId: string; batchNo: number; inputItemCode: string; inputOrder: number };
export type InstructReadParams = { instructNo: string };
export type ProcessInputVolumeReadParams = { runTimeInstructId: string; batchNo: number; inputItemCode: string; inputOrder: number };
export type ProcessOutputVolumeReadParams = { runTimeInstructId: string; itemCode: string };
export type SopLabelValueReadParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  sopLabelName: string;
  totalValueFlg: (typeof SOP_TOTAL_VALUE_FLG)[keyof typeof SOP_TOTAL_VALUE_FLG];
  referenceMode?: (typeof SOP_REFERENCE_MODE)[keyof typeof SOP_REFERENCE_MODE];
  achvOrder?: number;
};
export type TagValueReadParams = { runTimeInstructId: string; batchNo: number; tagName: string };
export type EquipmentReadParams = { equipmentCode: string; updatedStatus: string; logBookTreatMode: number };
export type SopFormulaReadParams = { runTimeInstructId: string; batchNo: number; formula: string };
export type ProcessInputReadParams = { runTimeInstructId: string; batchNo: number; objId: string };
export type ProcessInputAchievementsReadParams = { runTimeInstructId: string; batchNo: number };
export type InventoryReadParams = { instructNo: string; runTimeInstructId: string; batchNo: number; workflowId: string; objId: string };
export type PiPastDataReadParams = { tagName: string; piAfTime: string; retrievalMode: number };
export type SopObjectUpdateParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  updaterCode: string;
  updateTime: string;
  nextLabel?: string;
  comment?: string;
  deviationId?: string;
  deviationComment?: string;
  limitValue?: string;
  globalTagName?: string;
  runTimeInstructId?: string;
  workflowSet?: string;
  workflowSetSeqNo?: number;
  outputItemCode?: string;
  financeDate?: string;
  containerNo?: string;
  palletNo?: string;
  effectiveNumber?: number;
};
export type SopObjectLogBookUpdateParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  updaterCode: string;
  updateTime: string;
  nextLabel?: string;
  equipmentStatusCode?: string;
  comment?: string;
};
export type SopObjectMultipleUpdateParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  updaterCode: string;
  updateTime: string;
  comment?: string;
  deviationId?: string;
  deviationComment?: string;
  limitValue?: string;
  globalTagName?: string;
  runTimeInstructId?: string;
  workflowSet?: string;
  workflowSetSeqNo?: number;
  outputItemCode?: string;
  financeDate?: string;
  containerNo?: string;
  palletNo?: string;
  effectiveNumber?: number;
};
export type SopObjectInputItemUpdateParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  value2?: string;
  value3?: string;
  value4?: string;
  value5?: string;
  value6?: string;
  updaterCode: string;
  updateTime: string;
  nextLabel?: string;
  equipmentStatusCode?: string;
  remainBackIsChecked: string;
  comment?: string;
  paramList: SopInputItemParamList[];
};
export type SopInputItemParamList = {
  inputItemCode: string;
  itemName: string;
  lotNo: string;
  lotSeqNo: number;
  editionNo: string;
  inputOrder: number;
  divisionNo: number;
  divisionBranchNo: number;
  palletNo: string;
  containerNo: string;
  baleNo: string;
  weightUnitCode: string;
  unitName: string;
  takeoutValue: Bignumber;
  takeoutValueOld: number;
  achvAddWeight: Bignumber;
  achvAddWeightOld: number;
  remainValue: Bignumber;
  remainValueOld: number;
  abolishValue: number;
  abolishValueOld: number;
  differenceVolume: number;
  differenceVolumeOld: number;
  recipeOrder: number;
  treatMode: number;
  achvFlg: (typeof SOP_INPUT_ITEM.achvFlg)[keyof typeof SOP_INPUT_ITEM.achvFlg];
  inventoryZoneCode: string;
  stockWeightUnitRatio: Bignumber | null;
  isUnloadContainer: string;
  inputItemComment?: string;
  deviationId?: string;
  deviationComment?: string;
  limitValue?: string;
};
export type SopObjectSkipParams = {
  instructNo: string;
  processCode: string;
  batchNo: number;
  workflowId: string;
  objId: string;
  instructValue: string;
  value: string;
  updaterCode: string;
  updateTime: string;
  nextLabel?: string;
  comment?: string;
  deviationId?: string;
  deviationComment?: string;
};
export type SopObjectAchievementUpdateParams = {
  runTimeInstructId: string;
  workflowSet: string;
  workflowSetSeqNo: number;
  objId: string;
  batchNo: number;
  achvOrder: number;
  instructNo: string;
  processCode: string;
  workflowId: string;
  sopOrder: number;
  achvValue1: string;
  achvValue2: string;
  achvValue3: string;
  achvValue4: string;
  achvValue5: string;
  achvValue6: string;
  achvDate: string;
  updaterName: string;
  comment: string;
  deviationId?: string;
  updaterCode: string;
  updateTime: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopObjectDefinition(value: any): SopObjectDefinition {
  return {
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    processOrder: value.ProcessOrder,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    sopOrder: value.SOPOrder,
    objType: value.ObjType,
    issuedDate: value.IssuedDate,
    startPlanDate: value.StartPlanDate,
    endPlanDate: value.EndPlanDate,
    startDate: value.StartDate,
    endDate: value.EndDate,
    labelName: value.LabelName,
    baseLabelName: value.BaseLabelName,
    sopContents: value.SOPContents,
    objStatus: value.ObjStat,
    wfParamName: value.WFParamName,
    displayDefineName: value.DisplayDefineName,
    wfParamType: value.WFParamType,
    wfNumParam: value.WFNumParam,
    wfStrParam: value.WFStrParam,
    wfDatParam: value.WFDatParam,
    repeatStatus: value.RepeatStatus,
    nextObjId: value.NextObjID,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseConfirmation(value: any): Confirmation {
  return {
    confirmKind: value.ConfirmKind,
    confirmValue: value.ConfirmValue,
    confirm: value.Confirm,
    confirmOrder: value.ConfirmOrder,
    autoCommentFlg: value.AutoCommentFlg,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopLabelValue(value: any): SopLabelValue {
  return {
    labelValueResult: value.Result,
    achvValue1: value.AchvValue1,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    sopLabelName: value.SOPLabelName,
    totalValueFlg: value.TotalValueFlg,
    referenceMode: value.ReferenceMode,
    achvOrder: value.AchvOrder,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseTagValue(value: any): TagValue {
  return {
    tagValueResult: value.Result,
    tagValue: value.TagValue,
    errorMessage: value.ErrorMessage,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    tagName: value.TagName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSumOfYield(value: any): SumOfYield {
  return {
    sumOfYield: convertNullToUndefined(value.SumOfYield),
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    outputItemCode: value.OutputItemCode,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSumOfInputVolume(value: any): SumOfInputVolume {
  return {
    sumOfInput: convertNullToUndefined(value.SumOfInput),
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    inputItemCode: value.InputItemCode,
    inputOrder: value.InputOrder,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInstruct(value: any): Instruct {
  return {
    instructNo: value.InstructNo,
    productionVolume: convertNullToUndefined(value.ProductionVolume),
    displayLotNo: value.DisplayLotNo,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseProcessInputVolume(value: any): ProcessInputVolume {
  return {
    volume: convertNullToUndefined(value.Volume),
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    inputItemCode: value.InputItemCode,
    inputOrder: value.InputOrder,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseProcessOutputVolume(value: any): ProcessOutputVolume {
  return {
    sumYield: convertNullToUndefined(value.SumYield),
    runTimeInstructId: value.RunTimeInstructID,
    itemCode: value.ItemCode,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopObjectStatus(value: any): SopObjectStatus {
  return {
    isExecutable: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUpdatedSopObject(value: any): UpdatedSopObject {
  return {
    updateResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    instructValue: value.InstructValue,
    value: value.Value,
    value2: value.Value2,
    value3: value.Value3,
    value4: value.Value4,
    value5: value.Value5,
    value6: value.Value6,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
    nextLabel: value.NextLabel,
    equipmentStatusCode: value.EquipmentStatusCode,
    comment: value.Comment,
    deviationId: value.DeviationID,
    deviationComment: value.DeviationComment,
    limitValue: value.LimitValue,
    globalTagName: value.GlobalTagName,
    runTimeInstructId: value.RunTimeInstructID,
    workflowSet: value.WorkflowSet,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
    outputItemCode: value.OutputItemCode,
    financeDate: value.FinanceDate,
    containerNo: value.ContainerNo,
    palletNo: value.PalletNo,
    effectiveNumber: value.EffectiveNumber,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUpdatedSopObjectLogBook(value: any): UpdatedSopObjectLogBook {
  return {
    updateResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    instructValue: value.InstructValue,
    value: value.Value,
    value2: value.Value2,
    value3: value.Value3,
    value4: value.Value4,
    value5: value.Value5,
    value6: value.Value6,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
    nextLabel: value.NextLabel,
    equipmentStatusCode: value.EquipmentStatusCode,
    comment: value.Comment,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUpdatedSopObjectMultiple(value: any): UpdatedSopObjectMultiple {
  return {
    updateResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    instructValue: value.InstructValue,
    value: value.Value,
    value2: value.Value2,
    value3: value.Value3,
    value4: value.Value4,
    value5: value.Value5,
    value6: value.Value6,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
    comment: value.Comment,
    deviationId: value.DeviationID,
    deviationComment: value.DeviationComment,
    limitValue: value.LimitValue,
    globalTagName: value.GlobalTagName,
    runTimeInstructId: value.RunTimeInstructID,
    workflowSet: value.WorkflowSet,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
    outputItemCode: value.OutputItemCode,
    financeDate: value.FinanceDate,
    containerNo: value.ContainerNo,
    palletNo: value.PalletNo,
    effectiveNumber: value.EffectiveNumber,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUpdatedSopObjectInputItem(value: any): UpdatedSopObjectInputItem {
  return {
    updateResult: value.Result,
    errorMessage: value.ErrorMessage,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    instructValue: value.InstructValue,
    value: value.Value,
    value2: value.Value2,
    value3: value.Value3,
    value4: value.Value4,
    value5: value.Value5,
    value6: value.Value6,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
    nextLabel: value.NextLabel,
    equipmentStatusCode: value.EquipmentStatusCode,
    remainBackIsChecked: value.RemainBackIsChecked,
    comment: value.Comment,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopObjectSkip(value: any): UpdatedSopObjectSkip {
  return {
    skipResult: value.Result,
    errorMessage: value.ErrorMessage,
    batchNo: value.BatchNo,
    workflowId: value.WorkflowID,
    objId: value.ObjID,
    instructValue: value.InstructValue,
    value: value.Value,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
    nextLabel: value.NextLabel,
    comment: value.Comment,
    deviationId: value.DeviationID,
    deviationComment: value.DeviationComment,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseUpdateSopObjectAchievement(value: any): UpdatedSopObjectAchievement {
  return {
    updateAchvResult: value.Result,
    errorMessage: value.ErrorMessage,
    runTimeInstructId: value.RunTimeInstructID,
    workflowSet: value.WorkflowSet,
    workflowSetSeqNo: value.WorkflowSetSeqNo,
    objId: value.ObjID,
    batchNo: value.BatchNo,
    achvOrder: value.AchvOrder,
    instructNo: value.InstructNo,
    processCode: value.ProcessCode,
    workflowId: value.WorkflowID,
    sopOrder: value.SOPOrder,
    achvValue1: value.AchvValue1,
    achvValue2: value.AchvValue2,
    achvValue3: value.AchvValue3,
    achvValue4: value.AchvValue4,
    achvValue5: value.AchvValue5,
    achvValue6: value.AchvValue6,
    achvDate: value.AchvDate,
    updaterName: value.UpdaterName,
    comment: value.Comment,
    deviationId: value.DeviationID,
    updaterCode: value.UpdaterCode,
    updateTime: value.UpdateTime,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseEquipment(value: any): Equipment {
  return {
    equipmentCode: value.EquipmentCode,
    equipmentName: value.EquipmentName,
    equipmentStatusCode: value.EquipmentStatusCode,
    equipmentStatusName: value.EquipmentStatusName,
    equipmentStatusName2: value.EquipmentStatusName2,
    equipmentKind: value.EquipmentKind,
    effectivePeriodDate: value.EffectivePeriodDate,
    equipmentTypeCode: value.EquipmentTypeCode,
    equipmentTypeName: value.EquipmentTypeName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseSopFormula(value: any): SopFormula {
  return {
    calculateFormulaResult: value.Result,
    formulaResult: value.FormulaResult,
    errorMessage: value.ErrorMessage,
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    formula: value.Formula,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseProcessInput(value: any): ProcessInput {
  return {
    inputItemCode: value.InputItemCode,
    inputOrder: value.InputOrder,
    volume: value.Volume,
    itemName: value.ItemName,
    unitCode: value.UnitCode,
    unitName: value.UnitName,
    limitCheckOption: convertStringToNumber(
      value.LimitCheckOption,
    ) as (typeof SOP_INPUT_ITEM.limitCheckOption)[keyof typeof SOP_INPUT_ITEM.limitCheckOption],
    aboveUpperPoint: convertNullToUndefined(value.AboveUpperPoint),
    upperPoint: convertNullToUndefined(value.UpperPoint),
    lowerPoint: convertNullToUndefined(value.LowerPoint),
    belowLowerPoint: convertNullToUndefined(value.BelowLowerPoint),
    recipeOrder: value.RecipeOrder,
    stockWeightUnitRatio: convertNullToUndefined(value.StockWeightUnitRatio),
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    editionNo: value.EditionNo,
    zoneCode: value.ZoneCode,
    otherZoneGroup: value.OtherZoneGroup,
    achvFlg: convertStringToNumber(value.AchvFlg) as (typeof SOP_INPUT_ITEM.achvFlg)[keyof typeof SOP_INPUT_ITEM.achvFlg],
    divisionNo: value.DivisionNo,
    divisionBranchNo: value.DivisionBranchNo,
    lotNo: value.LotNo,
    baleNo: value.BaleNo,
    containerNo: value.ContainerNo,
    palletNo: value.PalletNo,
    achvAddWeight: value.AchvAddWeight,
    takeOutValue: convertNullToUndefined(value.TakeOutValue),
    displayLotNo: value.DisplayLotNo,
    makerLotNo: value.MakerLotNo,
    lotSeqNo: convertStringToNumber(value.LotSeqNo),
    runTimeZoneCode: value.RunTimeZoneCode,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseProcessInputAchievement(value: any): ProcessInputAchievement {
  return {
    runTimeInstructId: value.RunTimeInstructID,
    batchNo: value.BatchNo,
    inputItemCode: value.InputItemCode,
    inputOrder: value.InputOrder,
    editionNo: value.EditionNo,
    recipeOrder: value.RecipeOrder,
    zoneCode: value.ZoneCode,
    otherZoneGroup: value.OtherZoneGroup,
    volume: value.Volume,
    limitCheckOption: convertStringToNumber(
      value.LimitCheckOption,
    ) as (typeof SOP_INPUT_ITEM.limitCheckOption)[keyof typeof SOP_INPUT_ITEM.limitCheckOption],
    aboveUpperPoint: convertNullToUndefined(value.AboveUpperPoint),
    upperPoint: convertNullToUndefined(value.UpperPoint),
    lowerPoint: convertNullToUndefined(value.LowerPoint),
    belowLowerPoint: convertNullToUndefined(value.BelowLowerPoint),
    achvFlg: convertStringToNumber(value.AchvFlg) as (typeof SOP_INPUT_ITEM.achvFlg)[keyof typeof SOP_INPUT_ITEM.achvFlg],
    divisionNo: value.DivisionNo,
    divisionBranchNo: value.DivisionBranchNo,
    lotNo: value.LotNo,
    baleNo: value.BaleNo,
    containerNo: value.ContainerNo,
    palletNo: value.PalletNo,
    achvAddWeight: value.AchvAddWeight,
    takeOutValue: convertNullToUndefined(value.TakeOutValue),
    itemName: value.ItemName,
    displayLotNo: value.DisplayLotNo,
    makerLotNo: value.MakerLotNo,
    weightUnitCode: value.WeightUnitCode,
    unitName: value.UnitName,
    lotSeqNo: convertStringToNumber(value.LotSeqNo),
    runTimeZoneCode: value.RunTimeZoneCode,
    updateTime: value.UpdateTime,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseInventories(value: any): Inventory {
  return {
    inventoryResult: value.Result,
    errorMessage: value.ErrorMessage,
    itemCode: value.ItemCode,
    inputOrder: value.InputOrder,
    lotNo: value.LotNo,
    baleNo: value.BaleNo,
    palletNo: value.PalletNo,
    containerNo: value.ContainerNo,
    unitNumber: value.UnitNumber,
    availableNumber: value.AvailableNumber,
    zoneCode: value.ZoneCode,
    itemName: value.ItemName,
    displayLotNo: value.DisplayLotNo,
    itemType: value.ItemType,
    editionNo: value.EditionNo,
    makerLotNo: value.MakerLotNo,
    inputDate: value.InputDate,
    unitName: value.UnitName,
    lotSeqNo: value.LotSeqNo,
    qualityValue: value.QualityValue,
    weighingDone: value.WeighingDone,
    isMixedLot: value.IsMixedLot,
    baleMadeInstructNo: value.BaleMadeInstructNo,
    zoneName: value.ZoneName,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parsePiPastData(value: any): PiPastData {
  return {
    piPastDataResult: value.Result,
    errorMessage: value.ErrorMessage,
    tagName: value.TagName,
    piAfTime: value.PIAFTime,
    retrievalMode: value.RetrievalMode,
    tagValue: value.TagValue,
  };
}

const convertStringToNumber = (val: string): number | undefined => {
  const num = parseFloat(val);
  if (isNaN(num)) {
    return;
  }
  return num;
};

const convertNullToUndefined = (val: string): string | undefined => {
  if (val === 'NULL') {
    return;
  }
  return val;
};

export function calculateLimitPoint(
  limitCheckOption:
    | (typeof SOP_WF_NUM_PARAM.enterProcessData.limitCheckOption)[keyof typeof SOP_WF_NUM_PARAM.enterProcessData.limitCheckOption]
    | (typeof SOP_INPUT_ITEM.limitCheckOption)[keyof typeof SOP_INPUT_ITEM.limitCheckOption],
  objType: SopObjType,
  instructValue: string,
  isUpperPoint: boolean,
  limitPoint?: string,
): string {
  if (limitPoint == null) {
    return '';
  }
  const enterProcessData = SOP_WF_NUM_PARAM.enterProcessData.limitCheckOption;
  const inputItem = SOP_INPUT_ITEM.limitCheckOption;
  const isEnterProcessData = objType === SOP_OBJ_TYPE.enterProcessData;
  const isInputItem = objType === SOP_OBJ_TYPE.inputItem;
  const definedValue = new Bignumber(limitPoint);
  switch (limitCheckOption) {
    case isEnterProcessData && enterProcessData.fixedRelativeValue:
    case isEnterProcessData && enterProcessData.percentageRelativeValue:
    case isInputItem && inputItem.fixedRelativeValue:
    case isInputItem && inputItem.percentageRelativeValue: {
      if (!isNumberValue(instructValue)) {
        return '';
      }
      const referenceValue = new Bignumber(instructValue);
      const fixed =
        (isEnterProcessData && limitCheckOption === enterProcessData.fixedRelativeValue) ||
        (isInputItem && limitCheckOption === inputItem.fixedRelativeValue);
      const relativeValue = fixed ? definedValue : referenceValue.times(definedValue).div(100);
      const calculatedValue = isUpperPoint ? referenceValue.plus(relativeValue) : referenceValue.minus(relativeValue);
      return calculatedValue.toString();
    }
    case isEnterProcessData && enterProcessData.absoluteValue:
      return definedValue.toString();
    case isEnterProcessData && enterProcessData.none:
    case isEnterProcessData && enterProcessData.stringComparison:
    case isInputItem && inputItem.empty:
    case isInputItem && inputItem.none:
    default:
      return '';
  }
}

export function getLatestAchievement(achievements?: Achievement[]): Achievement | undefined {
  return achievements?.length ? achievements.reduce((a, b) => (a.achvOrder > b.achvOrder ? a : b), achievements[0]) : undefined;
}

export class SopObjects extends ImmutableMap<string, SopObject> {
  constructor(sopObjects?: SopObject[] | Iterable<SopObject>) {
    super(sopObject => sopObject.objId, sopObjects);
  }

  protected clone(sopObjects?: SopObject[] | Iterable<SopObject>): this {
    return new SopObjects(sopObjects) as this;
  }
}
