import { HttpClient, HttpContext, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DistinctSubject, HIDE_PROGRESS, isObject } from '@daikin-tic/dxone-com-lib';

import { QUEUE, QUEUE_OPTIONS, SOP_REQUEST_RESULT, SopGroupAchievementReadParams, SopGroupReadParams } from '../models/sop-group.model';
import { SopFormulaReadParams, SopLabelValueReadParams, SopObjectReadParams, TagValueReadParams } from '../models/sop-object.model';
import { MesRequestUsecase } from '../usecases/mes-request.usecase';
import { TaskUsecase } from '../usecases/task.usecase';

type SopGrpRequestParams = SopGroupAchievementReadParams | SopGroupReadParams;
type SopObjRequestParams = SopObjectReadParams | SopLabelValueReadParams | TagValueReadParams | SopFormulaReadParams;
type SopRequestParams = SopGrpRequestParams | SopObjRequestParams;
type RequestError = { name: string; error: HttpErrorResponse };

const comparePriority = (a: HttpRequest<unknown>, b: HttpRequest<unknown>) =>
  a.context.get(QUEUE_OPTIONS).priority - b.context.get(QUEUE_OPTIONS).priority;
const getApiName = (url: string): string => url.substring(url.lastIndexOf('/') + 1) || '';

@Injectable()
export class MesRequestInteractor extends MesRequestUsecase {
  get requestError$(): Observable<RequestError | undefined> {
    return this._requestError;
  }

  private _requests: HttpRequest<unknown>[] = [];

  private readonly _requestError = new DistinctSubject<RequestError | undefined>(undefined);

  constructor(
    private _http: HttpClient,
    private _taskUsecase: TaskUsecase,
  ) {
    super();
  }

  requestEnqueue(request: HttpRequest<unknown>): void {
    request.context.set(QUEUE, false);
    this._requests.push(request);
    const firstRequest = this._requests.shift() as HttpRequest<unknown>;
    this._requests.sort(comparePriority);
    this._requests = [firstRequest, ...this._requests];
    if (this._requests.length === 1) {
      this.executeRequest(request);
    }
  }

  cancelRequestQueue(apiName: string, params: SopRequestParams, priority: number): void {
    if (this._requests.length) {
      this.removeTargetRequest(priority, apiName, params);
    }
  }

  clearRequestQueue(priority?: number): void {
    if (priority == null) {
      this._requests = [];
    } else if (this._requests.length) {
      this.removeTargetRequest(priority);
    }
  }

  clearRequestError(): void {
    this._requestError.next(undefined);
  }

  addRequestError(name: string, error: HttpErrorResponse): void {
    this._requestError.next({ name, error });
  }

  private executeRequest(request: HttpRequest<unknown>): void {
    const { url, body, context } = request;
    const options = { context: new HttpContext().set(HIDE_PROGRESS, context.get(HIDE_PROGRESS)) };
    this._http.post(url, body, options).subscribe({
      next: (res: unknown) => {
        const response = Array.isArray(res) ? res[0] : res;
        if (response != null && response.Result === SOP_REQUEST_RESULT.error) {
          this.errorHandler(request);
          const err = new HttpErrorResponse({ error: { message: response.ErrorMessage }, status: 200 });
          this.addRequestError(getApiName(url), err);
        } else {
          this._taskUsecase.reflectResponse(getApiName(url), context.get(QUEUE_OPTIONS).params, res);
          this._requests.shift();
          if (this._requests.length) {
            this.executeRequest(this._requests[0]);
          }
        }
      },
      error: (err: HttpErrorResponse) => {
        this.addRequestError(getApiName(url), err);
        this.errorHandler(request);
      },
    });
  }

  private errorHandler(request: HttpRequest<unknown>): void {
    this._taskUsecase.reflectCancelRequest(getApiName(request.url), request.context.get(QUEUE_OPTIONS).params);
    this._requests.shift();
    if (this._requests.length) {
      this._requests.forEach(req => this._taskUsecase.reflectCancelRequest(getApiName(req.url), req.context.get(QUEUE_OPTIONS).params));
    }
    this._requests = [];
  }

  private removeTargetRequest(priority: number, apiName?: string, params?: SopRequestParams) {
    const requests = [...this._requests];
    const { processingReq, waitingReq } = requests.reduce(
      (acc, cur, i) => {
        if (i === 0) {
          acc.processingReq.push(cur);
        }
        if (this.isTargetRequest(cur, priority, apiName, params)) {
          this._taskUsecase.reflectCancelRequest(getApiName(cur.url), cur.context.get(QUEUE_OPTIONS).params);
        } else if (i !== 0) {
          acc.waitingReq.push(cur);
        }
        return acc;
      },
      { processingReq: [] as HttpRequest<unknown>[], waitingReq: [] as HttpRequest<unknown>[] },
    );
    this._requests = processingReq.concat(waitingReq);
  }

  private isTargetRequest(req: HttpRequest<unknown>, priority: number, apiName?: string, params?: SopRequestParams): boolean {
    const { params: requestedParams, priority: requestedPriority } = req.context.get(QUEUE_OPTIONS);
    if (apiName && params) {
      return (
        isObject(requestedParams) &&
        apiName === getApiName(req.url) &&
        priority === requestedPriority &&
        Object.entries(params).every(([key, val]) => requestedParams[key] === val)
      );
    }
    return priority === requestedPriority;
  }
}
