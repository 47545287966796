import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { AsyncSubject, Observable, Subject, asyncScheduler } from 'rxjs';
import { distinctUntilChanged, filter, first, map, subscribeOn, tap } from 'rxjs/operators';

import {
  AuthUsecase,
  Department,
  DepartmentUsecase,
  DistinctSubject,
  NeverError,
  UserUsecase,
  WebSocketSyncData,
  WebSocketUsecase,
  isNumber,
  isObject,
  isString,
  toStrictEntries,
} from '@daikin-tic/dxone-com-lib';

import { AllocationNotice } from '../models/allocation-notice.model';
import { Deviation } from '../models/deviation.model';
import {
  CreatedRecordComment,
  GrpSyncStatus,
  Order,
  OrderReadParams,
  RecordComment,
  RecordCommentCreateParams,
  RecordCommentReadParams,
  SOP_COMMENT_STATUS,
  SOP_DEVIATION_STATUS,
  SOP_EXECUTABLE_STATUS,
  SOP_GROUP_WF_STATUS,
  SOP_REQUEST_RESULT,
  SopGroup,
  SopGroupAchievement,
  SopGroupAchievementReadParams,
  SopGroupReadParams,
  SopGroupStatus,
  SopGroups,
  SopObjectAchievement,
  parseRecordComment,
  parseSopGroupAchievement,
  parseSopGroupStatus,
} from '../models/sop-group.model';
import {
  Confirmation,
  ConfirmationReadParams,
  Equipment,
  EquipmentReadParams,
  Instruct,
  InstructReadParams,
  Inventory,
  InventoryReadParams,
  ObjSyncStatus,
  PiPastData,
  PiPastDataReadParams,
  ProcessInput,
  ProcessInputAchievement,
  ProcessInputAchievementsReadParams,
  ProcessInputReadParams,
  ProcessInputVolume,
  ProcessInputVolumeReadParams,
  ProcessOutputVolume,
  ProcessOutputVolumeReadParams,
  SopEquipment,
  SopFormula,
  SopFormulaReadParams,
  SopLabelValue,
  SopLabelValueReadParams,
  SopObject,
  SopObjectAchievementUpdateParams,
  SopObjectDefinition,
  SopObjectDefinitionParamater,
  SopObjectDefinitionReadParams,
  SopObjectInputItemUpdateParams,
  SopObjectLogBookUpdateParams,
  SopObjectMultipleUpdateParams,
  SopObjectReadParams,
  SopObjectSkipParams,
  SopObjectStatus,
  SopObjectUpdateParams,
  SopObjects,
  SumOfInputVolume,
  SumOfInputVolumeReadParams,
  SumOfYield,
  SumOfYieldReadParams,
  TagValue,
  TagValueReadParams,
  UpdatedSopObject,
  UpdatedSopObjectAchievement,
  UpdatedSopObjectInputItem,
  UpdatedSopObjectLogBook,
  UpdatedSopObjectMultiple,
  UpdatedSopObjectSkip,
  parseInventories,
  parsePiPastData,
  parseSopFormula,
  parseSopLabelValue,
  parseSopObjectStatus,
  parseTagValue,
} from '../models/sop-object.model';
import { getParamVal, parseSopParams } from '../models/sop-paramater.model';
import { DeviationGateway } from '../usecases/deviation.gateway';
import { SopGroupGateway } from '../usecases/sop-group.gateway';
import { SopObjectGateway } from '../usecases/sop-object.gateway';
import { TaskUsecase } from '../usecases/task.usecase';

const getVersion = (): number => dayjs().tz().valueOf();
const sopGroupWfStatus = Object.values(SOP_GROUP_WF_STATUS).filter(status => status !== SOP_GROUP_WF_STATUS.completed);
const sopGroupExecutableStatuses = Object.values(SOP_EXECUTABLE_STATUS).filter(status => status === SOP_EXECUTABLE_STATUS.executable);

type SopGroupBasicInfo = Pick<SopGroup, 'instructNo' | 'processCode' | 'batchNo' | 'workflowId'>;

@Injectable()
export class TaskInteractor extends TaskUsecase {
  get sopGroups$(): Observable<SopGroups> {
    return this._sopGroups;
  }
  get sopObjects$(): Observable<SopObjects> {
    return this._sopObjects;
  }
  get date$(): Observable<string> {
    return this._date;
  }
  get operators$(): Observable<string[]> {
    return this._operators;
  }
  get showUnknownOperators$(): Observable<boolean> {
    return this._showUnknownOperators;
  }
  get statuses$(): Observable<number[]> {
    return this._statuses;
  }
  get executableStatuses$(): Observable<number[]> {
    return this._executableStatuses;
  }
  get deviations$(): Observable<Deviation[]> {
    return this._deviations;
  }
  get allocationNotice$(): Observable<AllocationNotice> {
    return this._allocationNotice;
  }

  private readonly _sopGroups = new DistinctSubject<SopGroups>(new SopGroups());
  private readonly _sopObjects = new DistinctSubject<SopObjects>(new SopObjects());
  private readonly _date = new DistinctSubject<string>('');
  private readonly _operators = new DistinctSubject<string[]>([]);
  private readonly _showUnknownOperators = new DistinctSubject<boolean>(true);
  private readonly _statuses = new DistinctSubject<number[]>(sopGroupWfStatus);
  private readonly _executableStatuses = new DistinctSubject<number[]>(sopGroupExecutableStatuses);
  private readonly _deviations = new DistinctSubject<Deviation[]>([]);
  private readonly _allocationNotice = new Subject<AllocationNotice>();
  private _signedInUserId?: string;

  constructor(
    private _authUsecase: AuthUsecase,
    private _departmentUsecase: DepartmentUsecase,
    private _deviationGateway: DeviationGateway,
    private _sopGroupGateway: SopGroupGateway,
    private _sopObjectGateway: SopObjectGateway,
    private _userUsecase: UserUsecase,
    private _webSocketUsecase: WebSocketUsecase,
  ) {
    super();

    this._authUsecase.authState$
      .pipe(
        subscribeOn(asyncScheduler),
        tap(({ user }) => (this._signedInUserId = user?.attributes?.name)),
        map(({ status }) => status === 'signedIn'),
        distinctUntilChanged(),
      )
      .subscribe(signedIn => (signedIn ? this.onSignIn() : this.onSignOut()));

    this._webSocketUsecase.message$
      .pipe(
        filter(message => message.action === 'sync' && message.data?.source === 'worker'),
        map(({ data }) => data as WebSocketSyncData<AllocationNotice>),
      )
      .subscribe(data => {
        switch (data.reason) {
          case 'update':
            this._allocationNotice.next(data.payload as AllocationNotice);
            break;
          case 'create':
          case 'delete':
            // nop
            break;
          default:
            throw new NeverError(data.reason);
        }
      });
  }

  changeDate(date: string): void {
    this._date.next(date);
  }

  changeOperators(operators: string[]): void {
    this._operators.next(operators);
  }

  changeShowUnknownOperators(showUnknownOperators: boolean): void {
    this._showUnknownOperators.next(showUnknownOperators);
  }

  changeStatuses(statuses: number[]): void {
    this._statuses.next(statuses);
  }

  changeExecutableStatuses(executableStatuses: number[]): void {
    this._executableStatuses.next(executableStatuses);
  }

  listOrders(params: OrderReadParams): Observable<Order[]> {
    const result = new AsyncSubject<Order[]>();
    this._sopGroupGateway.listOrders(params).subscribe({
      next: orders => {
        this.reflectListOrders(orders);
        result.next(orders);
      },
      error: err => {
        this._sopGroups.next(new SopGroups());
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listSopGroupAchievements(params: SopGroupAchievementReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopGroupGateway.listSopGroupAchievements(params, priority).subscribe({
      next: sopGroupAchievements => this.reflectSopGroupAchievements(sopGroupAchievements, true),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listSopObjectAchievements(params: SopGroupReadParams): Observable<never> {
    this.updateObjSyncFlg('', true, 'isSyncObjStatus');
    const result = new AsyncSubject<never>();
    this._sopGroupGateway.listSopObjectAchievements(params).subscribe({
      next: sopObjectAchievements => this.reflectSopObjectAchievements(sopObjectAchievements),
      error: err => {
        this.updateObjSyncFlg('', false, 'isSyncObjStatus');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listGroupRecordComments(params: RecordCommentReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopGroupGateway.listGroupRecordComments(params, priority).subscribe({
      next: recordComments => this.reflectGroupRecordComments(recordComments, params, true),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listDeviations(): Observable<never> {
    const result = new AsyncSubject<never>();
    this._deviationGateway.listDeviations().subscribe({
      next: deviations => this._deviations.next(deviations),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSopGroupStatus(params: SopGroupReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopGroupGateway.getSopGroupStatus(params, priority).subscribe({
      next: sopGroupStatus => this.reflectSopGroupStatus(sopGroupStatus, true),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createGroupRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment> {
    const result = new AsyncSubject<CreatedRecordComment>();
    this._sopGroupGateway.createGroupRecordComment(params).subscribe({
      next: createdRecordComment => {
        this.reflectCreateGroupRecordComment(createdRecordComment);
        result.next(createdRecordComment);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listSopObjectDefinitions(params: SopObjectDefinitionReadParams): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listSopObjectDefinitions(params).subscribe({
      next: sopObjectDefinitions => this.reflectSopObjectDefinitions(sopObjectDefinitions),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listConfirmations(params: ConfirmationReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncConfirmation');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listConfirmations(params).subscribe({
      next: confirmations => this.reflectConfirmations(confirmations, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncConfirmation');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listEquipments(params: EquipmentReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncEquipment');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listEquipments(params).subscribe({
      next: equipments => this.reflectEquipments(equipments, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncEquipment');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listProcessInputs(params: ProcessInputReadParams): Observable<never> {
    this.updateObjSyncFlg(params.objId, true, 'isSyncProcessInput');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listProcessInputs(params).subscribe({
      next: processInputs => this.reflectProcessInputs(processInputs, params.objId),
      error: err => {
        this.updateObjSyncFlg(params.objId, false, 'isSyncProcessInput');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listProcessInputAchievements(params: ProcessInputAchievementsReadParams): Observable<never> {
    this.updateObjSyncFlg('', true, 'isSyncProcessInputAchievement');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listProcessInputAchievements(params).subscribe({
      next: achievements => this.reflectProcessInputAchievements(achievements),
      error: err => {
        this.updateObjSyncFlg('', false, 'isSyncProcessInputAchievement');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listInventories(params: InventoryReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listInventories(params, priority).subscribe({
      next: inventories => this.reflectInventories(inventories, params.objId, true),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  listObjectRecordComments(params: RecordCommentReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.listObjectRecordComments(params, priority).subscribe({
      next: recordComments => this.reflectObjectRecordComments(recordComments, params.objId || '', true),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSopObjectAchievement(params: SopObjectReadParams): Observable<never> {
    this.updateObjSyncFlg(params.objId, true, 'isSyncObjStatus');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getSopObjectAchievement(params).subscribe({
      next: sopObjectAchievements => this.reflectSopObjectAchievement(sopObjectAchievements, params.objId),
      error: err => {
        this.updateObjSyncFlg(params.objId, false, 'isSyncObjStatus');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSumOfYield(params: SumOfYieldReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncSumOfYield');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getSumOfYield(params).subscribe({
      next: sumOfYields => this.reflectSumOfYield(sumOfYields, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncSumOfYield');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSumOfInputVolume(params: SumOfInputVolumeReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncSumOfInputVolume');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getSumOfInputVolume(params).subscribe({
      next: sumOfInputVolumes => this.reflectSumOfInputVolume(sumOfInputVolumes, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncSumOfInputVolume');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getInstruct(params: InstructReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncInstruct');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getInstruct(params).subscribe({
      next: instructs => this.reflectInstruct(instructs, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncInstruct');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getProcessInputVolume(params: ProcessInputVolumeReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncProcessInputVolume');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getProcessInputVolume(params).subscribe({
      next: processInputVolumes => this.reflectProcessInputVolume(processInputVolumes, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncProcessInputVolume');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getProcessOutputVolume(params: ProcessOutputVolumeReadParams, objId: string): Observable<never> {
    this.updateObjSyncFlg(objId, true, 'isSyncProcessOutputVolume');
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getProcessOutputVolume(params).subscribe({
      next: processInputVolumes => this.reflectProcessOutputVolume(processInputVolumes, objId),
      error: err => {
        this.updateObjSyncFlg(objId, false, 'isSyncProcessOutputVolume');
        result.error(err);
      },
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSopObjectStatus(params: SopObjectReadParams, priority: number): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getSopObjectStatus(params, priority).subscribe({
      next: sopObjectStatus => this.reflectSopObjectStatus(sopObjectStatus, true, params.objId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getSopLabelValue(
    params: SopLabelValueReadParams,
    priority: number,
    objId: string,
    labelId: string,
    hideProgress = true,
  ): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getSopLabelValue(params, priority, objId, labelId, hideProgress).subscribe({
      next: sopLabelValue => this.reflectSopLabelValue(sopLabelValue, labelId, objId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getTagValue(params: TagValueReadParams, priority: number, objId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getTagValue(params, priority, objId).subscribe({
      next: tagValue => this.reflectTagValue(tagValue, objId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  calculateFormula(
    params: SopFormulaReadParams,
    priority: number,
    objId: string,
    formulaId: string,
    hideProgress = true,
  ): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.calculateFormula(params, priority, objId, formulaId, hideProgress).subscribe({
      next: sopFormula => this.reflectSopFormula(sopFormula, formulaId, objId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  getPiPastData(params: PiPastDataReadParams, priority: number, objId: string): Observable<never> {
    const result = new AsyncSubject<never>();
    this._sopObjectGateway.getPiPastData(params, priority, objId).subscribe({
      next: piPastData => this.reflectPiPastData(piPastData, objId),
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObject(params: SopObjectUpdateParams): Observable<UpdatedSopObject> {
    const result = new AsyncSubject<UpdatedSopObject>();
    this._sopObjectGateway.updateSopObject(params).subscribe({
      next: updatedSopObject => {
        this.reflectUpdatedSopObject(updatedSopObject);
        result.next(updatedSopObject);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObjectLogBook(params: SopObjectLogBookUpdateParams): Observable<UpdatedSopObjectLogBook> {
    const result = new AsyncSubject<UpdatedSopObjectLogBook>();
    this._sopObjectGateway.updateSopObjectLogBook(params).subscribe({
      next: updatedSopObject => {
        this.reflectUpdatedSopObjectLogBook(updatedSopObject);
        result.next(updatedSopObject);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObjectMultiple(params: SopObjectMultipleUpdateParams[]): Observable<UpdatedSopObjectMultiple[]> {
    const result = new AsyncSubject<UpdatedSopObjectMultiple[]>();
    this._sopObjectGateway.updateSopObjectMultiple(params).subscribe({
      next: updatedSopObjects => {
        this.reflectUpdatedSopObjectMultiple(updatedSopObjects);
        result.next(updatedSopObjects);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObjectInputItem(params: SopObjectInputItemUpdateParams): Observable<UpdatedSopObjectInputItem> {
    const result = new AsyncSubject<UpdatedSopObjectInputItem>();
    this._sopObjectGateway.updateSopObjectInputItem(params).subscribe({
      next: updatedSopObject => {
        this.reflectUpdatedSopObjectInputItem(updatedSopObject);
        result.next(updatedSopObject);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObjectSkip(params: SopObjectSkipParams): Observable<UpdatedSopObjectSkip> {
    const result = new AsyncSubject<UpdatedSopObjectSkip>();
    this._sopObjectGateway.updateSopObjectSkip(params).subscribe({
      next: sopSkip => {
        this.reflectUpdateSopObjectSkip(sopSkip);
        result.next(sopSkip);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  updateSopObjectAchievement(params: SopObjectAchievementUpdateParams): Observable<UpdatedSopObjectAchievement> {
    const result = new AsyncSubject<UpdatedSopObjectAchievement>();
    this._sopObjectGateway.updateSopObjectAchievement(params).subscribe({
      next: updatedSopObjectAchievement => {
        this.reflectUpdatedSopObjectAchievement(updatedSopObjectAchievement);
        result.next(updatedSopObjectAchievement);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  createObjectRecordComment(params: RecordCommentCreateParams): Observable<CreatedRecordComment> {
    const result = new AsyncSubject<CreatedRecordComment>();
    this._sopObjectGateway.createObjectRecordComment(params).subscribe({
      next: createdRecordComment => {
        this.reflectCreateObjectRecordComment(createdRecordComment);
        result.next(createdRecordComment);
      },
      error: result.error.bind(result),
      complete: result.complete.bind(result),
    });
    return result.asObservable();
  }

  clearSopGroupComments(): void {
    const version = getVersion();
    const sopGroups = this._sopGroups.value.values().map(sopGroup => ({ ...sopGroup, comments: [], version }));
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  clearSopObjects(): void {
    this._sopObjects.next(new SopObjects());
  }

  clearSopObjectComments(): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => ({ ...sopObject, comments: [], version }));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  clearSopObjectReferenceValue(): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => ({
      ...sopObject,
      confirmations: [],
      tagValue: '',
      sopLabelValues: [],
      sopFormulas: [],
      sopEquipment: undefined,
      processInputs: [],
      processInputAchievements: [],
      inventories: [],
      version,
    }));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  reflectResponse(apiName: string, request: unknown, response: unknown): void {
    if (!isObject(request)) {
      return;
    }
    const objId = isString(request['objId']) ? request['objId'] : '';
    if (Array.isArray(response)) {
      switch (apiName) {
        case 'GetInventoryList': {
          const inventories = response.map(res => parseInventories(res));
          this.reflectInventories(inventories, objId, false);
          break;
        }
        case 'GetRecordComment': {
          const comments = response.map(res => parseRecordComment(res));
          if (objId) {
            this.reflectObjectRecordComments(comments, objId, false);
          } else {
            const { instructNo, processCode, batchNo, workflowId } = request as RecordCommentReadParams;
            const params = { instructNo, processCode, batchNo, workflowId };
            this.reflectGroupRecordComments(comments, params, false);
          }
          break;
        }
        case 'GetSOPObjAchvAndExecutableList': {
          const achievements = response.map(res => parseSopGroupAchievement(res));
          this.reflectSopGroupAchievements(achievements, false);
          break;
        }
        default:
          // nop
          break;
      }
    }
    if (isObject(response)) {
      switch (apiName) {
        case 'CalculateFormula': {
          const formulaId = isString(request['formulaId']) ? request['formulaId'] : '';
          this.reflectSopFormula(parseSopFormula(response), formulaId, objId);
          break;
        }
        case 'GetShowPIPastData':
          this.reflectPiPastData(parsePiPastData(response), objId);
          break;
        case 'GetSopGroupStatus':
          this.reflectSopGroupStatus(parseSopGroupStatus(response), false);
          break;
        case 'GetSopLabelValue': {
          const labelId = isString(request['labelId']) ? request['labelId'] : '';
          this.reflectSopLabelValue(parseSopLabelValue(response), labelId, objId);
          break;
        }
        case 'GetSopObjectStatus':
          this.reflectSopObjectStatus(parseSopObjectStatus(response), false, objId);
          break;
        case 'GetTagValue':
          this.reflectTagValue(parseTagValue(response), objId);
          break;
        default:
          // nop
          break;
      }
    }
  }

  reflectCancelRequest(apiName: string, request: unknown): void {
    if (!isObject(request)) {
      return;
    }
    const version = getVersion();
    const instructNo = isString(request['instructNo']) ? request['instructNo'] : '';
    const processCode = isString(request['processCode']) ? request['processCode'] : '';
    const batchNo = isNumber(request['batchNo']) ? request['batchNo'] : undefined;
    const workflowId = isString(request['workflowId']) ? request['workflowId'] : '';
    const objId = isString(request['objId']) ? request['objId'] : '';

    switch (apiName) {
      case 'CalculateFormula': {
        const formulaId = isString(request['formulaId']) ? request['formulaId'] : '';
        const sopObjects = this._sopObjects.value.values().map(sopObject => {
          if (sopObject.objId === objId && sopObject.sopFormulas?.length) {
            const sopFormulas = sopObject.sopFormulas.map(sopFormula =>
              sopFormula.formulaId === formulaId ? { ...sopFormula, calculateFormulaResult: SOP_REQUEST_RESULT.error } : sopFormula,
            );
            return { ...sopObject, version, sopFormulas };
          }
          return sopObject;
        });
        this._sopObjects.next(new SopObjects(sopObjects));
        break;
      }
      case 'GetInventoryList':
        this.updateObjSyncFlg(objId, false, 'isSyncInventory');
        break;
      case 'GetRecordComment':
        if (objId) {
          this.updateObjSyncFlg(objId, false, 'isSyncObjComments');
        } else {
          this.updateGrpSyncFlg({ instructNo, processCode, batchNo, workflowId }, false, 'isSyncWfComments');
        }
        break;
      case 'GetSopGroupStatus':
        this.updateGrpSyncFlg({ instructNo, processCode, batchNo, workflowId }, false, 'isSyncWfComments');
        break;
      case 'GetSopLabelValue': {
        const labelId = isString(request['labelId']) ? request['labelId'] : '';
        const sopObjects = this._sopObjects.value.values().map(sopObject => {
          if (sopObject.objId === objId) {
            const sopLabelValues = sopObject.sopLabelValues?.map(labelValue =>
              labelValue.labelId === labelId ? { ...labelValue, labelValueResult: SOP_REQUEST_RESULT.error } : labelValue,
            );
            return { ...sopObject, version, sopLabelValues };
          }
          return sopObject;
        });
        this._sopObjects.next(new SopObjects(sopObjects));
        break;
      }
      case 'GetSOPObjAchvAndExecutableList': {
        const sopGroups = this._sopGroups.value.values().map(sopGroup => {
          if (sopGroup.instructNo === instructNo && sopGroup.processCode === processCode) {
            return { ...sopGroup, version, isSyncWfExecutable: false, isSyncWfStatus: false };
          }
          return sopGroup;
        });
        this._sopGroups.next(new SopGroups(sopGroups));
        break;
      }
      case 'GetSopObjectStatus':
        this.updateObjSyncFlg(objId, false, 'isSyncObjExecutable');
        break;
      default:
        // nop
        break;
    }
  }

  private onSignIn(): void {
    this._departmentUsecase.department$
      .pipe(
        first(department => !!department?.departmentCode),
        map(department => department as Department),
      )
      .subscribe(({ groups }) => {
        this._userUsecase.users$
          .pipe(
            map(users => users.values()),
            first(users => !!users.length),
          )
          .subscribe(users => {
            const { groupId, userId } = users.find(user => user.userId === this._signedInUserId) || {};
            const operators =
              groups.some(group => group.groupId === groupId) && groupId !== 'none'
                ? users.filter(user => user.groupId === groupId && user.userId !== userId).map(user => user.userId)
                : [];
            this.changeOperators(operators);
          });
      });
  }

  private onSignOut(): void {
    this._sopGroups.next(new SopGroups());
    this._sopObjects.next(new SopObjects());
    this._date.next('');
    this._operators.next([]);
    this._showUnknownOperators.next(true);
    this._statuses.next(sopGroupWfStatus);
    this._executableStatuses.next(sopGroupExecutableStatuses);
    this._deviations.next([]);
  }

  private updateGrpSyncFlg(sopGroupBasicInfo: Partial<SopGroupBasicInfo>, flg: boolean, name: keyof GrpSyncStatus): void {
    const version = getVersion();
    const sopGroups = this._sopGroups.value
      .values()
      .map(sopGroup => (this.isSameSopGroup(sopGroup, sopGroupBasicInfo) ? { ...sopGroup, [name]: flg, version } : sopGroup));
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  private updateObjSyncFlg(objId: string, flg: boolean, name: keyof ObjSyncStatus): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId || !objId ? { ...sopObject, [name]: flg, version } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private isSameSopGroup(target: SopGroupBasicInfo, refer: Partial<SopGroupBasicInfo>): boolean {
    return (
      target.instructNo === refer.instructNo &&
      target.processCode === refer.processCode &&
      target.workflowId === refer.workflowId &&
      target.batchNo === refer.batchNo
    );
  }

  private reflectListOrders(orders: Order[]): void {
    if (orders[0].orderResult === SOP_REQUEST_RESULT.error) {
      this._sopGroups.next(new SopGroups());
    } else {
      const version = getVersion();
      const sopGroups = orders.map(order => {
        const { instructNo, processCode, workflowId, batchNo } = order;
        const uid = `${instructNo}_${processCode}_${workflowId}_${batchNo}`;
        return { ...order, uid, version } as SopGroup;
      });
      this._sopGroups.next(new SopGroups(sopGroups));
    }
  }

  private reflectSopGroupAchievements(sopGroupAchievements: SopGroupAchievement[], isSync: boolean): void {
    const version = getVersion();
    const sopGroups = this._sopGroups.value.values().map(sopGroup => {
      const { instructNo, processCode, workflowId } = sopGroup;
      if (sopGroupAchievements[0].instructNo === instructNo && sopGroupAchievements[0].processCode === processCode) {
        const achievement = sopGroupAchievements.find(achv => achv.workflowId === workflowId);
        const sopGrp = { ...sopGroup, isSyncWfStatus: isSync, isSyncWfExecutable: isSync, version };
        if (achievement && !isSync) {
          const { wfStatus, isExecutable, hasRecordComment, hasDeviation } = achievement;
          return { ...sopGrp, wfStatus, isExecutable, hasRecordComment, hasDeviation };
        }
        return sopGrp;
      }
      return sopGroup;
    });
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  private reflectSopObjectAchievements(sopObjectAchievements: SopObjectAchievement[]): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => this.integrateSopObjectAchievement(sopObjectAchievements, { ...sopObject, version, isSyncObjStatus: false }));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectGroupRecordComments(recordComments: RecordComment[], params: Partial<SopGroupBasicInfo>, isSyncWfComments: boolean): void {
    const version = getVersion();
    const comments =
      recordComments
        .filter(({ inputDate, comment }) => !!inputDate && !!comment)
        .map(({ inputDate, inputUserName, comment }) => ({ inputDate, inputUserName, comment })) || [];
    const sopGroups = this._sopGroups.value
      .values()
      .map(sopGroup => (this.isSameSopGroup(sopGroup, params) ? { ...sopGroup, version, comments, isSyncWfComments } : sopGroup));
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  private reflectSopGroupStatus(sopGroupStatus: SopGroupStatus, isSyncWfExecutable: boolean): void {
    const version = getVersion();
    const { isExecutable } = sopGroupStatus;
    const sopGroups = this._sopGroups.value.values().map(sopGroup => {
      const sopGrp = { ...sopGroup, isSyncWfExecutable, version };
      if (this.isSameSopGroup(sopGroup, sopGroupStatus)) {
        return isExecutable != null ? { ...sopGrp, isExecutable } : sopGrp;
      }
      return sopGroup;
    });
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  private reflectCreateGroupRecordComment(createdRecordComment: CreatedRecordComment): void {
    const version = getVersion();
    const sopGroups = this._sopGroups.value.values().map(sopGroup => {
      if (this.isSameSopGroup(sopGroup, createdRecordComment)) {
        const { updateTime: inputDate, comment, updaterCode } = createdRecordComment;
        const comments = [...(sopGroup.comments || []), { inputDate, comment, updaterCode }];
        return { ...sopGroup, version, comments };
      }
      return sopGroup;
    });
    this._sopGroups.next(new SopGroups(sopGroups));
  }

  private reflectSopObjectDefinitions(sopObjectDefinitions: SopObjectDefinition[]): void {
    const version = getVersion();
    const definitions = sopObjectDefinitions.reduce((acc, define) => {
      const sameObj = acc.find(obj => obj.objId === define.objId);
      const { wfParamName, displayDefineName, wfParamType, wfNumParam, wfStrParam, wfDatParam } = define;
      const params: SopObjectDefinitionParamater = { wfParamName, displayDefineName, wfParamType, wfNumParam, wfStrParam, wfDatParam };
      const addParam = { [wfParamName]: getParamVal(params) };
      if (sameObj) {
        sameObj.sopParams = { ...sameObj.sopParams, ...addParam };
        return acc;
      } else {
        const sopObj = toStrictEntries(params).reduce((obj, [key]) => {
          delete obj[key];
          return obj;
        }, define as SopObjectDefinition) as unknown as SopObject;
        delete sopObj.objStatus;
        return [...acc, { ...sopObj, version, sopParams: addParam }];
      }
    }, [] as SopObject[]);
    const sopObjects: SopObject[] = definitions.map(sopObj => ({ ...sopObj, sopParams: parseSopParams(sopObj.objType, sopObj.sopParams) }));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectConfirmations(confirmations: Confirmation[], objId: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, confirmations, isSyncConfirmation: false, version } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectEquipments(equipments: Equipment[], objId: string): void {
    const version = getVersion();
    const sopEquipment = equipments.reduce((acc, equipment) => {
      const { equipmentCode, equipmentName, equipmentStatusCode, equipmentStatusName, equipmentStatusName2: currentStatus } = equipment;
      switch (equipmentCode) {
        case 'UpdatedStatus':
          return { ...acc, instruction: equipmentStatusName };
        case 'LogBookTreatMode':
          return { ...acc, logBookTreatMode: equipmentStatusName };
        default:
          if (equipmentStatusCode && equipmentStatusName) {
            const recordValues = [...(acc.recordValues || []), { code: equipmentStatusCode, name: equipmentStatusName }];
            return { ...acc, equipmentCode, equipmentName, currentStatus, recordValues };
          }
          return { ...acc, equipmentCode, equipmentName, currentStatus };
      }
    }, {} as Partial<SopEquipment>) as SopEquipment;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, version, sopEquipment, isSyncEquipment: false } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectProcessInputs(processInputs: ProcessInput[], objId: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, version, isSyncProcessInput: false, processInputs } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectProcessInputAchievements(processInputAchievements: ProcessInputAchievement[]): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => ({ ...sopObject, version, isSyncProcessInputAchievement: false, processInputAchievements }));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectInventories(inventories: Inventory[], objId: string, isSyncInventory: boolean): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, inventories, isSyncInventory, version } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSopObjectAchievement(sopObjectAchievements: SopObjectAchievement[], objId: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === objId) {
        return this.integrateSopObjectAchievement(sopObjectAchievements, { ...sopObject, version, isSyncObjStatus: false });
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSumOfYield(sumOfYields: SumOfYield[], objId: string): void {
    const version = getVersion();
    const sumOfYield = sumOfYields.length ? sumOfYields[0] : undefined;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, sumOfYield, version, isSyncSumOfYield: false } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSumOfInputVolume(sumOfInputVolumes: SumOfInputVolume[], objId: string): void {
    const version = getVersion();
    const sumOfInputVolume = sumOfInputVolumes.length ? sumOfInputVolumes[0] : undefined;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject =>
        sopObject.objId === objId ? { ...sopObject, sumOfInputVolume, version, isSyncSumOfInputVolume: false } : sopObject,
      );
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectInstruct(instructs: Instruct[], objId: string): void {
    const version = getVersion();
    const instruct = instructs.length ? instructs[0] : undefined;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, instruct, version, isSyncInstruct: false } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectProcessInputVolume(processInputVolumes: ProcessInputVolume[], objId: string): void {
    const version = getVersion();
    const processInputVolume = processInputVolumes.length ? processInputVolumes[0] : undefined;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject =>
        sopObject.objId === objId ? { ...sopObject, processInputVolume, version, isSyncProcessInputVolume: false } : sopObject,
      );
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectProcessOutputVolume(processOutputVolumes: ProcessOutputVolume[], objId: string): void {
    const version = getVersion();
    const processOutputVolume = processOutputVolumes.length ? processOutputVolumes[0] : undefined;
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject =>
        sopObject.objId === objId ? { ...sopObject, processOutputVolume, version, isSyncProcessOutputVolume: false } : sopObject,
      );
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSopObjectStatus(sopObjectStatus: SopObjectStatus, isSyncObjExecutable: boolean, objId?: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      const { isExecutable } = sopObjectStatus;
      const sopObj = { ...sopObject, version, isSyncObjExecutable };
      if (sopObject.objId === sopObjectStatus.objId) {
        return isExecutable != null ? { ...sopObj, isExecutable } : sopObj;
      } else if (!objId) {
        return isExecutable != null ? { ...sopObj, isExecutable: SOP_EXECUTABLE_STATUS.notExecutable } : sopObj;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSopLabelValue(sopLabelValue: SopLabelValue, labelId: string, objId: string): void {
    const version = getVersion();
    const { labelValueResult, achvValue1 } = sopLabelValue;
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === objId) {
        if (sopObject.sopLabelValues?.length && sopObject.sopLabelValues.find(labelValue => labelValue.labelId === labelId)) {
          const sopLabelValues = sopObject.sopLabelValues.reduce(
            (acc, cur) => {
              if (cur.labelId === labelId) {
                const labelVal = { ...cur, labelValueResult };
                return [...acc, labelValueResult === SOP_REQUEST_RESULT.success ? { ...labelVal, achvValue1 } : labelVal];
              }
              return [...acc, cur];
            },
            [] as (SopLabelValue & { labelId: string })[],
          );
          return { ...sopObject, version, sopLabelValues };
        } else {
          return { ...sopObject, version, sopLabelValues: [...(sopObject.sopLabelValues || []), { ...sopLabelValue, labelId }] };
        }
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectTagValue(tagValue: TagValue, objId: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, ...tagValue, version } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectSopFormula(sopFormula: SopFormula, formulaId: string, objId: string): void {
    const version = getVersion();
    const { calculateFormulaResult, formulaResult } = sopFormula;
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === objId) {
        if (sopObject.sopFormulas?.length && sopObject.sopFormulas.find(formula => formula.formulaId === formulaId)) {
          const sopFormulas = sopObject.sopFormulas.reduce(
            (acc, cur) => {
              if (cur.formulaId === formulaId) {
                const formulaVal = { ...cur, calculateFormulaResult };
                return [...acc, calculateFormulaResult === SOP_REQUEST_RESULT.success ? { ...formulaVal, formulaResult } : formulaVal];
              }
              return [...acc, cur];
            },
            [] as (SopFormula & { formulaId: string })[],
          );
          return { ...sopObject, version, sopFormulas };
        } else {
          return { ...sopObject, version, sopFormulas: [...(sopObject.sopFormulas || []), { ...sopFormula, formulaId }] };
        }
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectPiPastData(piPastData: PiPastData, objId: string): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, ...piPastData, version } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectObjectRecordComments(recordComments: RecordComment[], objId: string, isSyncObjComments: boolean): void {
    const version = getVersion();
    const comments =
      recordComments
        .filter(({ inputDate, comment }) => !!inputDate && !!comment)
        .map(({ inputDate, inputUserName, comment }) => ({ inputDate, inputUserName, comment })) || [];
    const sopObjects = this._sopObjects.value
      .values()
      .map(sopObject => (sopObject.objId === objId ? { ...sopObject, version, comments, isSyncObjComments } : sopObject));
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdatedSopObject(updatedSopObject: UpdatedSopObject): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === updatedSopObject.objId) {
        delete (updatedSopObject as Partial<UpdatedSopObject>).comment;
        delete (updatedSopObject as Partial<UpdatedSopObject>).deviationComment;
        delete (updatedSopObject as Partial<UpdatedSopObject>).globalTagName;
        delete (updatedSopObject as Partial<UpdatedSopObject>).runTimeInstructId;
        delete (updatedSopObject as Partial<UpdatedSopObject>).workflowSet;
        delete (updatedSopObject as Partial<UpdatedSopObject>).workflowSetSeqNo;
        delete (updatedSopObject as Partial<UpdatedSopObject>).outputItemCode;
        delete (updatedSopObject as Partial<UpdatedSopObject>).financeDate;
        delete (updatedSopObject as Partial<UpdatedSopObject>).containerNo;
        delete (updatedSopObject as Partial<UpdatedSopObject>).palletNo;
        delete (updatedSopObject as Partial<UpdatedSopObject>).effectiveNumber;
        return { ...sopObject, ...updatedSopObject, version } as SopObject;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdatedSopObjectLogBook(updatedSopObject: UpdatedSopObjectLogBook): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === updatedSopObject.objId) {
        delete (updatedSopObject as Partial<UpdatedSopObjectLogBook>).comment;
        return { ...sopObject, ...updatedSopObject, version } as SopObject;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdatedSopObjectMultiple(updatedSopObjects: UpdatedSopObjectMultiple[]): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      const updatedSopObject = updatedSopObjects.find(object => object.objId === sopObject.objId);
      if (updatedSopObject) {
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).comment;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).deviationComment;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).globalTagName;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).runTimeInstructId;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).workflowSet;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).workflowSetSeqNo;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).outputItemCode;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).financeDate;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).containerNo;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).palletNo;
        delete (updatedSopObject as Partial<UpdatedSopObjectMultiple>).effectiveNumber;
        return { ...sopObject, ...updatedSopObject, version } as SopObject;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdatedSopObjectInputItem(updatedSopObject: UpdatedSopObjectInputItem): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === updatedSopObject.objId) {
        delete (updatedSopObject as Partial<UpdatedSopObjectInputItem>).comment;
        return { ...sopObject, ...updatedSopObject, version } as SopObject;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdateSopObjectSkip(sopObjectSkip: UpdatedSopObjectSkip): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === sopObjectSkip.objId) {
        delete (sopObjectSkip as Partial<UpdatedSopObjectSkip>).comment;
        delete (sopObjectSkip as Partial<UpdatedSopObjectSkip>).deviationComment;
        return { ...sopObject, ...sopObjectSkip, version } as SopObject;
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectUpdatedSopObjectAchievement(updatedSopObjectAchievement: UpdatedSopObjectAchievement): void {
    const version = getVersion();
    const { objId, achvOrder, achvValue1, achvValue2, achvValue3, achvValue4, achvValue5, achvValue6, achvDate, updaterName } =
      updatedSopObjectAchievement;
    const updatedAchv = { achvOrder, achvValue1, achvValue2, achvValue3, achvValue4, achvValue5, achvValue6, achvDate, updaterName };
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === objId) {
        const achievements = sopObject.achievements?.map(achv => (achv.achvOrder === achvOrder ? updatedAchv : achv));
        return { ...sopObject, achievements, version };
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private reflectCreateObjectRecordComment(createdRecordComment: CreatedRecordComment): void {
    const version = getVersion();
    const sopObjects = this._sopObjects.value.values().map(sopObject => {
      if (sopObject.objId === createdRecordComment.objId) {
        const { updateTime: inputDate, comment, updaterCode } = createdRecordComment;
        const comments = [...(sopObject.comments || []), { inputDate, comment, updaterCode }];
        return { ...sopObject, version, comments };
      }
      return sopObject;
    });
    this._sopObjects.next(new SopObjects(sopObjects));
  }

  private integrateSopObjectAchievement(sopObjectAchievements: SopObjectAchievement[], sopObject: SopObject): SopObject {
    const targetAchievements = sopObjectAchievements.filter(achievement => achievement.objId === sopObject.objId);
    if (targetAchievements) {
      const achievements = targetAchievements
        .filter(achievement => achievement.achvValue1)
        .map(({ achvOrder, achvValue1, achvValue2, achvValue3, achvValue4, achvValue5, achvValue6, updaterName, achvDate }) => {
          return { achvOrder, achvValue1, achvValue2, achvValue3, achvValue4, achvValue5, achvValue6, updaterName, achvDate };
        });
      const { hasRecordComment, hasDeviation } = targetAchievements.reduce(
        (acc, cur) => ({
          hasRecordComment: acc.hasRecordComment === SOP_COMMENT_STATUS.exist ? acc.hasRecordComment : cur.hasRecordComment,
          hasDeviation: acc.hasDeviation === SOP_DEVIATION_STATUS.exist ? acc.hasDeviation : cur.hasDeviation,
        }),
        { hasRecordComment: targetAchievements[0].hasRecordComment, hasDeviation: targetAchievements[0].hasDeviation },
      );
      const latestAchv = targetAchievements.reduce((a, b) => (a.achvOrder > b.achvOrder ? a : b), targetAchievements[0]);
      const { objStatus, instructValue, workflowSet, workflowSetSeqNo } = latestAchv;
      return { ...sopObject, objStatus, instructValue, hasRecordComment, hasDeviation, workflowSet, workflowSetSeqNo, achievements };
    } else {
      return sopObject;
    }
  }
}
